// lihtlogin.js

import { lihtlogin_langs_recover_password } from '../../../app_modules/javascript/src/languages';

$(function() {

    // Launch check remember on load
    check_remember();

    $("#btn-logout").on( 'click', (event) =>  {
        event.preventDefault();
        lihtlogin_logout_action();
    });

    $(".go-forgot").on( 'click', function( e ) {
        e.preventDefault();
        console.log( 'go-forgot' );
        hide_show( '.login-layer', '.forgot-layer', 200 );
    });

    $(".go-login").on( 'click', function( e ) {
        e.preventDefault();
        hide_show('.forgot-layer', '.login-layer', 200 );
    });

    $(".btn-login").on( 'click', function( e ) {
        e.preventDefault();

        let remember_btn = 0;
        if ( $('#remember' ).prop( 'checked' ) ) {
            remember_btn = 1;
        }
        lihtlogin_fields["email"] = lihtlogin_elements.email_field.val();
        lihtlogin_fields["password"] = lihtlogin_elements.password_field.val();
        lihtlogin_fields["remember"] = remember_btn

        fetch_actions_action( global_values, lihtlogin_fields, global_urls.login, 'login' )
        .then( data => {
            liht_login_refresh_ui( data );
        })
        .catch( err => {
            console.log( err );
        });
    });

    $(".btn-verify-code").on( 'click', function( e ) {
        e.preventDefault();
        check_code_user_email = $( "#check_code_user_email" ).val();
        check_code_verify_code = $( "#check_code_verify_code" ).val();
        check_code_previous_ip = $( "#check_code_previous_ip" ).val();

        lihtlogin_fields["user_email"] = check_code_user_email;
        lihtlogin_fields["check_code_verify_code"] = check_code_verify_code;
        lihtlogin_fields["check_code_previous_ip"] = check_code_previous_ip;

        fetch_actions_action( global_values, lihtlogin_fields, global_urls.login, 'verify_code' )
        .then( data => {
            liht_login_refresh_ui( data );
        })
        .catch( err => {
            console.log( err );
        });

    });

});

window.liht_login_refresh_ui = ( data ) => {

    console.log(data);
    spin_out();

    if ( data.status == 401 ) {
        lihtlogin_elements.error_login_title.text( data.error_title );
        lihtlogin_elements.error_login_msg.text( data.error_msg );
        lihtlogin_elements.login_alert_wrapper.fadeIn( 500 );
        $(".login-alert-wrapper").fadeIn(500);            

        setTimeout(() => {
            lihtlogin_elements.login_alert_wrapper.fadeOut( 500 );
        }, 5000);

    } else if ( data.status == 201 ) {
        hide_show('.login-layer', '.code-layer', 200);
        lihtlogin_elements.verify_code_title.show();
        lihtlogin_elements.check_code_user_email.val( data.user.email );
        lihtlogin_elements.check_code_previous_ip.val( data.ip );

    } else if ( data.status == 202 ) {
        hide_show('.code-layer', '.login-layer', 200);
        lihtlogin_elements.verify_code_title.show();
        lihtlogin_elements.check_code_user_email.val( data.user.email );
        lihtlogin_elements.check_code_previous_ip.val( data.ip );
        toast_msg(data.error_msg, data.error_title, 'error', 'up');

    } else {
        localStorage.setItem('auth_token', data.auth_token);
        global_values[ 'auth_token' ] = data.auth_token;

        if ( lihtlogin_elements.remember.val() == "1" ){
            localStorage.setItem('user_email', data.user.email);
        } else {
            localStorage.removeItem('user_email');
        }

        const url = data.locale + data.user.app.url; // For redirect to init app
        lihtlogin_elements.login_redirect_form.html( redirect_form() );
        lihtlogin_elements.login_redirect_form.attr( 'action', url );
        lihtlogin_elements.login_redirect_form.trigger( 'submit' );

    }

}


window.lihtlogin_logout_action = () => {
    spin_in( '' );
    setTimeout(() => {
        spin_out();
        localStorage.removeItem('auth_token');
        window.location.href = '/';
    }, 1000);

}


