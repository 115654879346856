import consumer from "./consumer"

consumer.subscriptions.create("LocationChannel", {
    connected() {
        console.log( 'Connected to location channel' );
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        console.log( data );

        if ( data.status === 200 ) {
            clear_overlays();

            const drivers = data.drivers;
            const brooms = data.brooms;

            print_drivers_on_map( drivers );
            print_drivers_on_map( brooms );

        } else if ( data.status === 201 ) {

            for ( let i=0; i<markers.length; i++ ) {
                if ( markers[i].id === data.broom.id ) {
                    const myLatLng = new google.maps.LatLng( data.broom.lat, data.broom.lng );
                    const current_marker = markers[i];
                    current_marker.setIcon( get_broom_dynamic_icon( data.move_icon, current_marker.icon.fillColor ) );
                    current_marker.setPosition( myLatLng );
                }
            }

        } else if ( data.status === 202 ) {

            if ( localStorage.getItem( 'auth_token' ) ) {
                list_brooms_with_services_sidebar();
            }
        } else if ( data.status === 600 ) {

            $("#processes-alert").fadeIn(300);

            setTimeout(() => {
                $("#processes-alert").fadeOut(300);
            }, 6000);

        }

    }

});

