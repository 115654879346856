// lihtusers.js

import { 
    lihtlogin_langs_recover_password,
    lihtusers_langs_end_of_load,
    lihtusers_menu_labels,
    global_jquery_validate_langs,
    lihtusers_avatar_data,
    lihtusers_delete_user_alert_data
} from '../../../app_modules/javascript/src/languages';

$(function() {

    // Form Validation
    $.extend($.validator.messages, global_jquery_validate_langs[ global_values.locale ] );

    // Init pages loading all users
    if ( window.location.pathname.includes('/lihtusers/')  ) {
        fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list' )
        .then( data => {
            lihtusers_load_users_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });
    }

    // Browsing between actions
    $("#lihtusers-list-users").on( 'click', ( e ) => {
        e.preventDefault();
        lihtusers_refresh_form();

        lihtusers_elements.list_users.addClass('active');
        lihtusers_elements.new_user.removeClass('active');
        hide_show('#new-user-layer','#list-user-layer',200);

        // Delete present and reload first users page
        $("#lihtusers-users-table tbody tr").remove();
        //lihtusers_load_users_list_action(auth_token, values);
        fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list' )
        .then( data => {
            lihtusers_load_users_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });

    });

    $("#lihtusers-new-user").on( 'click', ( e ) => {
        e.preventDefault();
        lihtusers_refresh_form();
        
        lihtusers_elements.new_user.addClass('active');
        lihtusers_elements.list_users.removeClass('active');
        hide_show('#list-user-layer','#new-user-layer',200);
    });

    // reload on scroll
    // $(window).on( 'scroll', function() {
    //     console.log( 'Se ejecuta window on scroll' );
    //     if ( lihtusers_elements.list_user_layer.is(":visible") ) {
    //         if($(window).scrollTop() == $(document).height() - $(window).height()) {
    //             lihtusers_fields_vals["start_load"] = $("#start-load").val();

    //             fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'load_users_list' )
    //             .then( data => {
    //                 lihtusers_load_users_list_ui( data );
    //             })
    //             .catch( err => {
    //                 console.log( err );
    //             });
    //         }
    //     }
    // });

    $("#btn-create-new-user").on( 'click', ( e ) =>  {
        e.preventDefault();
        validate_new_form('#new-user-form');

        if ( $("#new-user-form").valid() && lihtusers_check_strong_password(locale, $("#password").val()) ) {
            /// Load form fields
            load_form_fields_before_send();

            /// Send fetch
            fetch_actions_action_with_file( global_values, lihtusers_fields_vals, global_urls.users, 'create_new_user' )
            .then( data => {
                lihtusers_create_new_user_ui( data );
            })
            .catch( err => {
                console.log( err );
            });
        }
    });

    $("#btn-edit-user").on( 'click', ( e ) =>  {
        e.preventDefault();
        const new_email = $( "#email" ).val().toLowerCase();
        const previous_email = $( "#previous_email" ).val();

        validate_new_form_edit( '#edit-user-form', new_email, previous_email );

        if ( $("#edit-user-form").valid() && lihtusers_check_strong_password(locale, $("#password").val()) ) {
            /// Load form fields
            load_form_fields_before_send();

            /// Send fetch
            fetch_actions_action_with_file( global_values, lihtusers_fields_vals, global_urls.users, 'edit_user' )
            .then( data => {
                lihtusers_edit_user_ui( data );
            })
            .catch( err => {
                console.log( err );
            });
        }
    });

    $("#user-avatar").on( 'change', () =>  {
        let oFReader = new FileReader();
        oFReader.readAsDataURL( document.getElementById("user-avatar").files[0] );
        oFReader.fileName = document.getElementById("user-avatar").files[0];
        oFReader.onload = function ( oFREvent ) {
            document.getElementById("upload-preview").src = oFREvent.target.result;
            
            console.log( oFREvent.target.fileName );
            lihtusers_fields.user_avatar.next('.custom-file-label').html(oFREvent.target.fileName.name);
            lihtusers_elements.file_info.html( lihtusers_avatar_data[ global_values.locale ].size + ": " + oFREvent.target.fileName.size + " - " + lihtusers_avatar_data[ global_values.locale ].type + ": " + oFREvent.target.fileName.type )

        };

    });

    $( document ).on( 'click', '#btn-delete-image-user', function( e ) {
        e.preventDefault();
        /// Load form fields
        load_form_fields_before_send();

        fetch_actions_action_with_file( global_values, lihtusers_fields_vals, global_urls.users, 'delete_user_image' )
        .then( data => {
            spin_out();
            if ( data.status === 200 ) {
                $( "#upload-preview" ).attr( 'src', '' );
                $( "#btn-delete-image-user" ).hide();
                toast_msg(data.ok_msg, 'OK', 'success', 'down');
            } else {
                toast_msg(data.error_msg, 'Error', 'error', 'down');
            }
        })
        .catch( err => {
            console.log( err );
        });

    })

});


window.lihtusers_load_users_list_ui = ( data ) => {
    console.log(data);
    spin_out();

    $("#start-load").val( data.start_load );

    let users = data.users;

    let html = '';
    for ( let i=0; i<users.length; i++ ) {
        html += compose_html_tr_table_user( users[i] );
    }

    lihtusers_elements.users_table_tbody.append(html);

    if ( !data.is_finished && $("#list-user-layer").is(":visible") ) {
        toast_msg( 'CARGANDO...', 'Cargados ' +  data.start_load + ' de ' + data.users_total + ' usuarios', 'success', 'down' );
        lihtusers_fields.is_finished.val( '' );
    } else {
        toast_msg( 'TODOS CARGADOS', 'Cargados ' +  data.users_total + ' de ' + data.users_total + ' usuarios', 'info', 'down' );
        lihtusers_fields.is_finished.val( '1' );
    }

}

window.lihtusers_edit_user_ui = ( data ) => {
    console.log(data);
    spin_out();

    if ( data.status == 401 ) {
        toast_msg(data.error_msg, 'Error', 'error', 'down');
    } else {

        $("#start-load").val( data.start_load );

        let users = data.users;

        let html = '';
        for ( let i=0; i<users.length; i++ ) {
            html += compose_html_tr_table_user( users[i] );
        }

        lihtusers_elements.users_table_tbody.html(html);

        lihtusers_elements.list_users.addClass('active');
        lihtusers_elements.new_user.removeClass('active');
        hide_show('#new-user-layer','#list-user-layer',200);

        // reset form fields
        lihtusers_refresh_form();

        if ( data.is_finished && $(".list-user-layer").is(":visible") ) {
            toastr.options = {
                "newestOnTop": true,
                "positionClass": "toast-top-right",
                "preventDuplicates": false,
                "showDuration": "300",
                "hideDuration": "1000",
                "timeOut": "5000",
                "showEasing": "swing",
                "hideEasing": "linear",
                "showMethod": "fadeIn",
                "hideMethod": "fadeOut"
            }
            toastr[type]( title, text );
        }
    }

}

window.lihtusers_create_new_user_ui = ( data ) => {
    console.log(data);
    spin_out();

    if ( data.status == 401 ) {
        toast_msg(data.error_msg, 'Error', 'error', 'down');
    } else {
        lihtusers_refresh_form();

        lihtusers_elements.list_users.addClass('active');
        lihtusers_elements.new_user.removeClass('active');
        hide_show('#new-user-layer','#list-user-layer',200);
    
        // Add new user to table with prepend
        const new_user_tr = compose_html_tr_table_user( data.user );
        lihtusers_elements.users_table_tbody.prepend( new_user_tr );

        // reset form fields
        lihtusers_refresh_form();

        toast_msg(data.ok_msg, 'OK', 'success', 'down');
    }
}

window.lihtusers_delete_user_action = (user_id, user_name) => {

    // Set the user_id as param
    lihtusers_fields_vals[ "user_id" ] = user_id;

    Swal.fire({
        title: lihtusers_delete_user_alert_data[global_values.locale].title,
        text: user_name + ' ' + lihtusers_delete_user_alert_data[global_values.locale].text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: lihtusers_delete_user_alert_data[global_values.locale].btn_delete,
        cancelButtonText: lihtusers_delete_user_alert_data[global_values.locale].btn_cancel
    }).then( result => {
        if ( result.isConfirmed ) {
            spin_in();
            /// Send fetch
            fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'delete_user' )
            .then( data => {
                console.log(data);
                spin_out();
                if ( data.status == 401 ) {
                    Swal.fire(
                        data.error_title,
                        data.error_msg,
                        'error'
                    );
                } else if ( data.status == 200 ) {
                    toast_msg(data.ok_msg, 'OK', 'success', 'down');
                    $('#user-'+data.user_id).fadeOut(200);
                    setTimeout(() => {
                        $('#user-'+data.user_id).remove();
                    }, 200 );
                }
            })
            .catch( err => {
                console.log( err );
            });            
        }
    })
}

window.lihtusers_boolean_change_action = (user_id, field) => {
    
    lihtusers_fields_vals[ "field" ] = field;
    lihtusers_fields_vals[ "user_id" ] = user_id;

    fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'modify_booleans' )
    .then( data => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'Error', 'error', 'down');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'OK', 'success', 'down');
        }
    })
    .catch( err => {
        console.log( err );
    });
}


/***** HELPERS */
window.lihtusers_print_bootstrap_switch_from_true_false = (val, id, field) => {
    if (val) {
        return '<div class="form-check form-switch form-switch-lg text-center"><input class="form-check-input" type="checkbox" checked onchange="lihtusers_boolean_change_action(\''+id+'\', \''+field+'\');"></div>';
    } else {
        return '<div class="form-check form-switch form-switch-lg text-center"><input class="form-check-input" type="checkbox" onchange="lihtusers_boolean_change_action(\''+id+'\', \''+field+'\');"></div>';
    }
}

window.lihtusers_refresh_form = () => {
    lihtusers_fields.name_surname.val('');
    lihtusers_fields.email.val('').prop( 'disabled', false );
    lihtusers_fields.password.val('');
    lihtusers_fields.password_confirm.val('');
    lihtusers_fields.app_id.prop("selectedIndex", 1);
    lihtusers_fields.inherit_id.prop("selectedIndex", 0);
    lihtusers_fields.is_actived.prop( 'checked', true );
    lihtusers_fields.newsletter.prop( 'checked', true );
    lihtusers_fields.user_avatar.next('.custom-file-label').text('');
    lihtusers_elements.btn_delete_image_user.hide();

    lihtusers_elements.edit_user_form.attr( 'id', 'new-user-form' );
    lihtusers_elements.btn_create_new_user.show();
    lihtusers_elements.btn_edit_user.hide();
    lihtusers_elements.edit_info_show.hide();
    lihtusers_elements.upload_preview.removeAttr('src');
    lihtusers_elements.file_info.text('');
}

window.validate_new_form = ( form_id ) => {

    $( form_id ).validate({
        rules: {
            name_surname: {
                required: true
            },
            email: {
                required: true,
                email: true,
                remote: '/lihtusers/api/v1/check_email'
            },
            password: {
                required: true,
                minlength: 8
            },
            password_confirm: {
                required: true,
                minlength: 8,
                equalTo: "#password"
            }
            
        },
        messages: {
            // email: {
            //     required: "Please enter a email address",
            //     email: "Please enter a vaild email address"
            // },
            // password: {
            //     required: "Please provide a password",
            //     minlength: "Your password must be at least 5 characters long"
            // },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
        }
    });

}

window.validate_new_form_edit = ( form_id , new_email, previous_email) => {

    let rules = {
        name_surname: {
            required: true
        },
        email: {
            required: true,
            email: true
            //remote: '/lihtusers/api/v1/check_email'
        },
        password: {
            //required: true,
            minlength: 8
        },
        password_confirm: {
            //required: true,
            minlength: 8,
            equalTo: "#password"
        }
    };

    if ( new_email !== previous_email ) {
        rules = {
            name_surname: {
                required: true
            },
            email: {
                required: true,
                email: true,
                remote: '/lihtusers/api/v1/check_email'
            },
            password: {
                //required: true,
                minlength: 8
            },
            password_confirm: {
                //required: true,
                minlength: 8,
                equalTo: "#password"
            }
        };
    }

    $( form_id ).validate({
        rules: rules,
        messages: {
            // email: {
            //     required: "Please enter a email address",
            //     email: "Please enter a vaild email address"
            // },
            // password: {
            //     required: "Please provide a password",
            //     minlength: "Your password must be at least 5 characters long"
            // },
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
        }
    });

}

window.lihtusers_check_strong_password = (locale, password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if( password != "" ) {
        if (strongRegex.test(password)){
            $("#password-error").text('').hide();
            return true;
        } else {
            $("#password-error").text(lihtlogin_langs_recover_password[ global_values.locale ].regex.body).show();
            return false;
        }
    } else {
        return true;
    }
}

window.load_form_fields_before_send = () => {

    lihtusers_fields_vals[ "name" ] = lihtusers_fields.name_surname.val();
    lihtusers_fields_vals[ "email" ] = lihtusers_fields.email.val();
    lihtusers_fields_vals[ "password" ] = lihtusers_fields.password.val();
    lihtusers_fields_vals[ "app_id" ] = lihtusers_fields.app_id.find('option:selected').val();
    lihtusers_fields_vals[ "inherit_id" ] = lihtusers_fields.inherit_id.find('option:selected').val();

    if ( lihtusers_fields.is_actived.prop('checked') ) {
        lihtusers_fields_vals[ "is_actived" ] = true;
    } else {
        lihtusers_fields_vals[ "is_actived" ] = false;
    }

    if ( lihtusers_fields.newsletter.prop('checked') ) {
        lihtusers_fields_vals[ "newsletter" ] = true;
    } else {
        lihtusers_fields_vals[ "newsletter" ] = false;
    }

    // Apps
    let apps=[];
    $('.user-apps:checked').each(function() {
        apps.push(parseInt($(this).val()));
    });
    lihtusers_fields_vals[ "apps" ] = apps;

    // Groups
    let groups=[];
    $('.user-groups:checked').each(function() {
        groups.push(parseInt($(this).val()));
    });
    lihtusers_fields_vals[ "groups" ] = groups;

    const user_avatar = document.querySelector('#user-avatar')
    lihtusers_fields_vals[ "avatar" ] = user_avatar.files[0];
    
}

window.compose_html_tr_table_user = ( user ) => {

    let html = '';
    html += '<tr id="user-'+user.id+'">';
    if( user.avatar ){
        html +=     '<td><img src="'+user.avatar+'" class="img-circle elevation-2" style="max-width: 2.5rem;" /></td>';
    } else {
        html +=     '<td><img alt="'+user.name+'" class="img-circle elevation-2" style="max-width: 2.5rem;" src="/mail_images/user_icon_default.png"></td>';
    }

    html +=     '<td>';
    html +=             '<a href="#" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">';
    html +=                 '<span class="visually-hidden">Toggle Dropdown</span>';
    html +=             '</a>';
    html +=             '<div class="dropdown-menu">';
    html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_edit_user_load(\''+user.id+'\');">'+lihtusers_menu_labels[global_values.locale].edit_menu.edit+'</a></li>';
    html +=                 '<li><a class="dropdown-item" href="javascript:lihtusers_delete_user_action(\''+user.id+'\',\''+user.name+'\');">'+lihtusers_menu_labels[global_values.locale].edit_menu.delete+'</a></li>';
    html +=             '</div>';
    html +=     '</td>';
    html +=     '<td>' + user.name + '</td>';
    html +=     '<td><a href="mailto:' + user.email + '">' + user.email + '</a></td>';
    html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(user.is_actived, user.id, 'ac' ) + '</td>';
    html +=     '<td>' + lihtusers_print_bootstrap_switch_from_true_false(user.newsletter, user.id, 'nl' ) + '</td>';
    html += '</tr>';

    return html;
}

window.lihtusers_edit_user_load = (user_id) => {
    lihtusers_fields_vals[ "user_id" ] = user_id;
    fetch_actions_action( global_values, lihtusers_fields_vals, global_urls.users, 'request_user_data_to_edit' )
    .then( data => {
        spin_out();
        console.log(data);

        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'Error', 'error', 'down');
        } else if ( data.status == 200 ) {

            // Fillin the form with user values
            assign_user_values_to_fields( data.user, data.avatar );

            lihtusers_elements.new_user_form.attr( 'id', 'edit-user-form' );
            //$("#new-user-form").attr( 'id', 'edit-user-form' );
            lihtusers_elements.btn_create_new_user.hide();
            lihtusers_elements.btn_edit_user.show();
            lihtusers_elements.edit_info_show.show();

            lihtusers_elements.new_user.addClass('active');
            lihtusers_elements.list_users.removeClass('active');

            hide_show('#list-user-layer', '#new-user-layer', 200);
        }

    })
    .catch( err => {
        console.log( err );
    });
};

window.assign_user_values_to_fields = ( user, avatar ) => {

    lihtusers_fields.name_surname.val( user.name );
    lihtusers_fields.email.val( user.email );
    lihtusers_fields.password.val( '' );
    lihtusers_fields.password_confirm.val( '' );
    lihtusers_fields.app_id.val( user.app_id );
    lihtusers_fields.inherit_id.val( user.inherit_id );
    lihtusers_fields.previous_email.val( user.email );

    if ( user.is_actived ) {
        lihtusers_fields.is_actived.prop( 'checked', true );
    } else {
        lihtusers_fields.is_actived.prop( 'checked', false );
    }
    if ( user.newsletter ) {
        lihtusers_fields.newsletter.prop( 'checked', true );
    } else {
        lihtusers_fields.newsletter.prop( 'checked', false );
    }

    // Apps
    let apps=user.apps.split(',');
    for (let i=0; i<apps.length; i++){
        $("#app-"+apps[i]).prop( 'checked', true );
    }

    // Groups
    let groups=user.groups.split(',');
    for (let i=0; i<groups.length; i++){
        $("#group-"+groups[i]).prop( 'checked', true );
    }

    if (!avatar){
        lihtusers_elements.btn_delete_image_user.hide();
        lihtusers_elements.upload_preview.removeAttr('src');
    } else {
        lihtusers_elements.upload_preview.prop( 'src', avatar );
        lihtusers_elements.btn_delete_image_user.show();
    }
    lihtusers_fields.user_avatar.next('.custom-file-label').text('');
    lihtusers_fields.edit_user_id.val( user.id );

}