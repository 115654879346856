//engines_browser.js

$(function() {

    $(".application-reload-page").on( 'click', (event) =>  {
        event.preventDefault();
        const path = window.location.pathname;
        console.log("RELOAD LAUNCHED");
        setTimeout(() => {
            browse_to_engine( path );
        }, 500 )
    });


    $("#lihtconfigs-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/configs/configs/index';
        if ( !browsing.lihtconfigs_sidebar_menu.hasClass('menu-open') ) {
            browse_to_engine(url);
        }
    });

    $("#lihtusers-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/lihtusers/users/index';
        if ( !browsing.lihtusers_sidebar_menu.hasClass('menu-open') ) {
            browse_to_engine(url);
        }
    });
    
    $("#drivers-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/drivers/drivers/index';
        if ( !browsing.drivers_sidebar_menu.hasClass('menu-open') ) {
            browse_to_engine(url);
        }
    });
    
    $("#maps-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/maps/maps/index';
        if ( !browsing.maps_sidebar_menu.hasClass('menu-open') ) {
            browse_to_engine(url);
        }
    });

    $("#services-sidebar-menu").on( 'click', (event) => {
        event.preventDefault();
        const url = '/es/services/services/index';
        if ( !browsing.services_sidebar_menu.hasClass('menu-open') ) {
            browse_to_engine(url);
        }
    });


});

window.browse_to_engine = (url) => {

    browsing.login_redirect_form.html( redirect_form() );
    browsing.login_redirect_form.attr( 'action', url );
    browsing.login_redirect_form.trigger( 'submit' );

}
