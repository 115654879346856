// For jquery-validate
export const global_jquery_validate_langs = {
    "en": {
        required: "This field is required.",
        remote: "This value alredy exist in the systema, please write other.",
        email: "Please enter a valid email address.",
        password: "Please provide a password",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        equalTo: "Please enter the same value again.",
        time: "Please enter a valid time, between 00:00 and 23:59",
        maxlength: $.validator.format( "Please enter no more than {0} characters." ),
        minlength: $.validator.format( "Please enter at least {0} characters." ),
        rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
        range: $.validator.format( "Please enter a value between {0} and {1}." ),
        max: $.validator.format( "Please enter a value less than or equal to {0}." ),
        min: $.validator.format( "Please enter a value greater than or equal to {0}." ),
        step: $.validator.format( "Please enter a multiple of {0}." )
    },
    "es": {
        required: "Este campo es obligatorio.",
        remote: "Este valor ya existe en el sistema, escriba otro.",
        email: "Por favor, escribe una dirección de correo válida.",
        password: "Por favor, escribe una contraseña",
        url: "Por favor, escribe una URL válida.",
        date: "Por favor, escribe una fecha válida.",
        dateISO: "Por favor, escribe una fecha (ISO) válida.",
        number: "Por favor, escribe un número válido.",
        digits: "Por favor, escribe sólo dígitos.",
        creditcard: "Por favor, escribe un número de tarjeta válido.",
        equalTo: "Por favor, escribe el mismo valor de nuevo.",
        extension: "Por favor, escribe un valor con una extensión aceptada.",
        time: "Por favor, introduzca una hora válida entre 00:00 and 23:59",
        maxlength: $.validator.format( "Por favor, no escribas más de {0} caracteres." ),
        minlength: $.validator.format( "Por favor, no escribas menos de {0} caracteres." ),
        rangelength: $.validator.format( "Por favor, escribe un valor entre {0} y {1} caracteres." ),
        range: $.validator.format( "Por favor, escribe un valor entre {0} y {1}." ),
        max: $.validator.format( "Por favor, escribe un valor menor o igual a {0}." ),
        min: $.validator.format( "Por favor, escribe un valor mayor o igual a {0}." ),
        step: $.validator.format( "Por favor, escriba el valor con formato {0}" ),
        nifES: "Por favor, escribe un NIF válido.",
        nieES: "Por favor, escribe un NIE válido.",
        cifES: "Por favor, escribe un CIF válido."
    }
}

// For Lihtlogin engine
export const lihtlogin_langs_recover_password = {
    "es": {
        "key": {
            "title": "Error en:",
            "subtitle": "Código clave",
            "body": "Comprueba que esta bien pegado el código porque debe tener 128 caracteres"
        },
        "equals": {
            "title": "Error en:",
            "subtitle": "Contraseñas",
            "body": "Ambas contraseñas deben ser iguales"
        },
        "regex": {
            "title": "Error en:",
            "subtitle": "Contraseñas",
            "body": "La contraseña debe tener al menos 8 caracteres incluyendo, una mayúscula, una minúscula y 1 número"
        } 
    },
    "en": {
        "key": {
            "title": "Error in:",
            "subtitle": "Code key",
            "body": "Please check that the code has been copied and pasted successfully because should content 128 characters"
        },
        "equals": {
            "title": "Error in:",
            "subtitle": "Passwords",
            "body": "Password and Password confirm must be the same"
        },
        "regex": {
            "title": "Error in:",
            "subtitle": "Contraseñas",
            "body": "The new password must have 8 characters at least, with one uppercase, one lowercase and one number"
        } 
    }
}


// For Lihtusers engine
export const lihtusers_langs_end_of_load = {
    "es": {
        "is_finished": {
            "title": "Finalizado",
            "body": "Se han cargado todos los usuarios"
        }
    },
    "en": {
        "is_finished": {
            "title": "Ended",
            "body": "All users hve been loaded"
        }
    }
};

export const lihtusers_menu_labels = {
    "es": {
        "edit_menu": {
            "edit": "Editar usuario",
            "delete": "Eliminar usuario",
            "new": "Nuevo usuario"
        }
    },
    "en": {
        "edit_menu": {
            "edit": "Edit user",
            "delete": "Delete user",
            "new": " New user"
        }
    }
};

export const lihtusers_avatar_data = {
    "es": {
        "size": "Tamaño",
        "type": "Tipo"    
    },
    "en": {
        "size": "Size",
        "type": "Type"
    }
}

export const lihtusers_delete_user_alert_data = {
    "es": {
        "title": "¿Esta seguro?",
        "text": "va a ser eliminado del sistema",
        "btn_cancel": "Cancelar",
        "btn_delete": "Si, eliminar"
    },
    "en": {
        "title": "Are you sure?",
        "text": "are going to be deleted ",
        "btn_cancel": "Cancel",
        "btn_delete": "Yes, delete"
    }
}