// variables.js

/*** Global Values */
const global_values = {
    locale: $("#locale").val(),
    authenticity_token: $("#authenticity_token").val(),
    auth_token: localStorage.getItem( 'auth_token' ),
};
window.global_values = global_values;

const global_urls = {
    login: '/login/api/v1/',
    config: '/configs/api/v1/',
    users: '/lihtusers/api/v1/',
    drivers: '/drivers/api/v1/',
    maps: '/maps/api/v1/'
};
window.global_urls = global_urls;

const car_icon_transitalia = {
    //path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
    path: "M320 64C320 99.35 291.3 128 256 128C220.7 128 192 99.35 192 64C192 28.65 220.7 0 256 0C291.3 0 320 28.65 320 64zM288 160C323.3 160 352 188.7 352 224V272C352 289.7 337.7 304 320 304H318.2L307.2 403.5C305.4 419.7 291.7 432 275.4 432H236.6C220.3 432 206.6 419.7 204.8 403.5L193.8 304H192C174.3 304 160 289.7 160 272V224C160 188.7 188.7 160 224 160H288zM63.27 414.7C60.09 416.3 57.47 417.8 55.33 419.2C51.7 421.6 51.72 426.4 55.34 428.8C64.15 434.6 78.48 440.6 98.33 446.1C137.7 456.1 193.5 464 256 464C318.5 464 374.3 456.1 413.7 446.1C433.5 440.6 447.9 434.6 456.7 428.8C460.3 426.4 460.3 421.6 456.7 419.2C454.5 417.8 451.9 416.3 448.7 414.7C433.4 406.1 409.9 399.8 379.7 394.2C366.6 391.8 358 379.3 360.4 366.3C362.8 353.3 375.3 344.6 388.3 347C420.8 352.9 449.2 361.2 470.3 371.8C480.8 377.1 490.6 383.5 498 391.4C505.6 399.5 512 410.5 512 424C512 445.4 496.5 460.1 482.9 469C468.2 478.6 448.6 486.3 426.4 492.4C381.8 504.7 321.6 512 256 512C190.4 512 130.2 504.7 85.57 492.4C63.44 486.3 43.79 478.6 29.12 469C15.46 460.1 0 445.4 0 424C0 410.5 6.376 399.5 13.96 391.4C21.44 383.5 31.24 377.1 41.72 371.8C62.75 361.2 91.24 352.9 123.7 347C136.7 344.6 149.2 353.3 151.6 366.3C153.1 379.3 145.4 391.8 132.3 394.2C102.1 399.8 78.57 406.1 63.27 414.7H63.27z",
    fillColor: '#2A3849',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.car_icon_transitalia = car_icon_transitalia;

const broom_icon_green = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: 'green',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_green = broom_icon_green;

const broom_icon_red = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: 'red',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_red = broom_icon_red;

const broom_icon_blue = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: '#007bff',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_blue = broom_icon_blue;

const broom_icon_orange = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: '#da8010',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_orange = broom_icon_orange;

const broom_icon_purple = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: '#c59eff',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_purple = broom_icon_purple;

const broom_icon_magenta = {
    path: "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z",
    fillColor: '#ff00ff',
    scale: 0.05,
    fillOpacity: 1,
    strokeWeight: 0,
    strokeColor: 'white',
    //anchor: new google.maps.Point(40, 40)
}
window.broom_icon_magenta = broom_icon_magenta;

const broom_vehicle_path = "M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z";
window.broom_vehicle_path = broom_vehicle_path;

const broom_still_path = "M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z";
window.broom_still_path = broom_still_path;

const broom_color = [ 'green', 'red', '#007bff', '#da8010', '#c59eff', '#ff00ff' ];
window.broom_color = broom_color;

const icon_colors = [ broom_icon_green, broom_icon_red, broom_icon_blue, broom_icon_orange, broom_icon_purple, broom_icon_magenta ];
window.icon_colors = icon_colors;

let markers = [];
window.markers = markers;

let map_routes = [];
window.map_routes = map_routes;

const standar_map = [
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
];
window.standar_map = standar_map;

const night_map = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#5e5e5e" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#5e5e5e" }], // was #d59563
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
    },
    {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#787878" }], // f3d19c
    },
    {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
    },
    {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#5e5e5e" }],
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
    },
];
window.night_map = night_map;

const dark_map = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212121"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    }
];
window.dark_map = dark_map;
  
const get_broom_dynamic_icon = ( move_icon, color ) => {

    var path;
    if ( move_icon == 'in_vehicle' ) {
        path = broom_vehicle_path;
    } else {
        path = broom_still_path;
    }

    icon = {
        path: path,
        fillColor: color,
        scale: 0.05,
        fillOpacity: 1,
        strokeWeight: 0,
        strokeColor: 'white',
        //anchor: new google.maps.Point(40, 40)
    }

    return icon;

}
window.get_broom_dynamic_icon = get_broom_dynamic_icon;

/*** Global Values //END */



/**** Redirect Form */
const redirect_form = () => {
    let form = '' +
    //'<form action="/' + url + '" method="post" id="login-redirect-form">' + 
    '<input type="hidden" name="auth_token" value="' + global_values.auth_token + '">' + 
    '<input type="hidden" name="authenticity_token" value="' + global_values.authenticity_token + '">' + 
    '';
    //'</form>';
    return form;
}
window.redirect_form = redirect_form;
/**** Redirect Form //END */




/*** Sidebars */
const browsing = {
    login_redirect_form: $( "#login-redirect-form" ),
    lihtconfigs_sidebar_menu: $( "#lihtconfigs-sidebar-menu" ),
    lihtusers_sidebar_menu: $( "#lihtusers-sidebar-menu" ),
    drivers_sidebar_menu: $( "#drivers-sidebar-menu" ),
    maps_sidebar_menu: $( "#maps-sidebar-menu" ),
    services_sidebar_menu: $( "#services-sidebar-menu" )
}
window.browsing = browsing;

/*** Sidebars //END */





/*** Lihtlogin Values */
const lihtlogin_fields = {
    email: $("#email").val(),
    password: $("#password").val(),
};
window.lihtlogin_fields = lihtlogin_fields;

const lihtlogin_elements = {
    email_field: $( "#email" ),
    password_field: $( "#password" ),
    error_login_title: $( "#error-login-title" ),
    error_login_msg: $( "#error-login-msg" ),
    login_alert_wrapper: $( "#login-alert-wrapper" ),
    login_alert_wrapper_forgot_password: $( "#login-alert-wrapper-forgot-password" ),
    login_alert_wrapper_verify_code: $( "#login-alert-wrapper-verify-code" ),
    verify_code_title: $( "#verify-code-title" ),
    user_email: $( "#user_email" ),
    check_code_user_email: $( "#check_code_user_email" ),
    check_code_previous_ip: $( "#check_code_previous_ip" ),
    login_redirect: $( "#login-redirect" ),
    login_redirect_form: $( "#login-redirect-form" ),
    remember: $( "#remember" )
}
window.lihtlogin_elements = lihtlogin_elements;

/*** Lihtlogin Values //END */





/*** Lihtconfig Values */
const lihtconfig_fields = {
    app_id: $( "#app_id" ).val(),
    app_name: $( "#app_name" ).val(),
    app_url: $( "#app_url" ).val(),
    group_id: $( "#group_id" ).val(),
    group_name: $( "#group_name" ).val()
}
window.lihtconfig_fields = lihtconfig_fields;

const lihtconfig_elements = {
    app_id_field: $( "#app_id" ),
    app_name_field: $( "#app_name" ),
    app_url_field: $( "#app_url" ),
    group_id_field: $( "#group_id" ),
    group_name_field: $( "#group_name" ),
    lihtconfigs_apps_list: $( "#lihtconfigs-apps-list" ),
    lihtconfigs_groups_list: $( "#lihtconfigs-groups-list" )
    //btn_create_modify_app: $( "#btn-create-modify-app" )
}
window.lihtconfig_elements = lihtconfig_elements;
/*** Lihtconfig Values //END */








/*** Lihtusers Values */
const lihtusers_fields = {
    name_surname: $("#name-surname"),
    email: $("#email"),
    password: $("#password"),
    password_confirm: $("#password_confirm"),
    app_id: $("#app_id"),
    inherit_id: $("#inherit_id"),
    is_actived: $("#is_actived"),
    newsletter: $("#newsletter"),
    user_avatar: $("#user-avatar"),
    edit_user_id: $("#edit_user_id"),
    previous_email: $("#previous_email"),
    is_finished: $("#is_finished")
}
window.lihtusers_fields = lihtusers_fields;

const lihtusers_fields_vals = {
    
}
window.lihtusers_fields_vals = lihtusers_fields_vals

const lihtusers_elements = {
    list_user_layer: $("#list-user-layer"),
    new_user_layer: $("#new-user-layer"),
    list_users: $("#lihtusers-list-users"),
    new_user: $("#lihtusers-new-user"),
    users_table: $("#lihtusers-users-table"),
    users_table_tbody: $("#lihtusers-users-table tbody"),

    new_user_form: $("#new-user-form"),
    edit_user_form: $("#edit-user-form"),
    btn_delete_image_user: $("#btn-delete-image-user"),
    btn_create_new_user: $("#btn-create-new-user"),
    btn_edit_user: $("#btn-edit-user"),
    upload_preview: $("#upload-preview"),
    file_info: $("#lihtusers-file-info"),
    edit_info_show: $(".lihtusers-edit-info-show")

}
window.lihtusers_elements = lihtusers_elements;

/*** Lihtusers Values //END */








/*** Drivers Values */
const drivers_fields = {
    only_actived: $("#only_actived"),
    only_broom: $("#only_broom"),
    is_finished: $("#is_finished")
}
window.drivers_fields = drivers_fields

const drivers_fields_vals = {
    
}
window.drivers_fields_vals = drivers_fields_vals

const drivers_elements = {
    list_driver_layer: $("#list-driver-layer"),
    drivers_table: $("#drivers-drivers-table"),
    drivers_table_tbody: $("#drivers-drivers-table tbody"),
}
window.drivers_elements = drivers_elements;

/*** Drivers Values //END */



/*** Maps Values */
const maps_fields = {
}
window.maps_fields = maps_fields

const maps_fields_vals = {
}
window.maps_fields_vals = maps_fields_vals

const maps_elements = {
}
window.maps_elements = maps_elements;
/*** Maps Values //END */





