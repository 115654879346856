// lihtconfigs.js

//import { event } from "jquery";

$(function() {

    $( "#btn-create-modify-app" ).on( 'click', ( e ) => {
        e.preventDefault();

        lihtconfig_fields["app_id"] = lihtconfig_elements.app_id_field.val();
        lihtconfig_fields["app_name"] = lihtconfig_elements.app_name_field.val();
        lihtconfig_fields["app_url"] = lihtconfig_elements.app_url_field.val();

        if ( lihtconfig_fields.app_id !== "" ) {

            fetch_actions_action( global_values, lihtconfig_fields, global_urls.config, 'create_update_apps' )
            .then( data => {
                lihtconfigs_create_update_apps_ui( data );
            })
            .catch( err => {
                console.log( err );
            });

        } else {
            toast_msg( 'Debe escribir un ID numerico', 'Error', 'error', 'down' );
        }

    });

    $(".btn-create-modify-group").on( 'click', ( e ) => {
        e.preventDefault();

        lihtconfig_fields["group_id"] = lihtconfig_elements.group_id_field.val();
        lihtconfig_fields["group_name"] = lihtconfig_elements.group_name_field.val();

        if (lihtconfig_fields.group_id !== "") {
            fetch_actions_action( global_values, lihtconfig_fields, global_urls.config, 'create_update_groups' )
            .then( data => {
                lihtconfigs_create_update_groups_ui( data );
            })
            .catch( err => {
                console.log( err );
            });
        } else {
            toast_msg( 'Debe escribir un ID numerico', 'warning', 'down' );
        }

    });

});


window.lihtconfigs_create_update_apps_ui = ( data ) => {
    console.log(data);
    spin_out();
    if ( data.status == 200 ) {
        let html = '';
        html += '<td>'+data.app.id+'</td>';
        html += '<td>'+data.app.name+'</td>';
        html += '<td>'+data.app.url+'</td>';

        if ( $("#app-row-"+data.app.id).length == 0 ) {
            $("#lihtconfigs-apps-list").append('<tr>'+html+'</tr>');
            toast_msg('La app se ha creado correctamente', 'OK', 'success', 'down');
        } else {
            $("#app-row-"+data.app.id).empty();
            $("#app-row-"+data.app.id).html(html);
            toast_msg('La app se ha actualizado correctamente', 'OK', 'success', 'down');
        }

        lihtconfig_elements.app_id_field.val( '' );
        lihtconfig_elements.app_name_field.val( '' );
        lihtconfig_elements.app_url_field.val( '' );
            

    } else {
        // Verify code error
        toast_msg('Se ha producido un error durante el proceso', 'Error', 'error', 'down');
    }
}


window.lihtconfigs_create_update_groups_ui = ( data ) => {
    console.log(data);
    spin_out();
    if ( data.status == 200 ) {
        let html = '';
        html += '<td>'+data.group.id+'</td>';
        html += '<td>'+data.group.name+'</td>';

        if ( $("#group-row-"+data.group.id).length == 0 ) {
            $("#lihtconfigs-groups-list").append('<tr>'+html+'</tr>');
            toast_msg('El grupo se ha creado correctamente', 'OK', 'success', 'down');
        } else {
            $("#group-row-"+data.group.id).empty();
            $("#group-row-"+data.group.id).html(html);
            toast_msg('El grupo se ha actualizado correctamente', 'OK', 'success', 'down');
        }

        lihtconfig_elements.group_id_field.val( '' );
        lihtconfig_elements.group_name_field.val( '' )
            

    } else {
        // Verify code error
        toast_msg('Se ha producido un error durante el proceso', 'Error', 'error', 'down');
    }
}


