//drivers.js

// Referencias de jQuery
const googleMapKey = 'AIzaSyCQ7vBkB7o8v8cTck2-2mcO1rD9XIkSdXk';

import { global_jquery_validate_langs } from '../../../app_modules/javascript/src/languages';
import { Loader } from "@googlemaps/js-api-loader";

//const GoogleChartsNode = require('google-charts-node')

$(function() {

    // Form Validation
    $.extend($.validator.messages, global_jquery_validate_langs[ global_values.locale ] );

    // set only_actived in localstorage
    if ( localStorage.getItem( 'only_actived' ) ) {
        drivers_fields.only_actived.attr( 'checked', true );
    } else {
        drivers_fields.only_actived.attr( 'checked', false );
    }

    if ( localStorage.getItem( 'only_broom' ) ) {
        drivers_fields.only_broom.attr( 'checked', true );
    } else {
        drivers_fields.only_broom.attr( 'checked', false );
    }

    $("#only_actived").on( 'click', function() {
        if ( drivers_fields.only_actived.is( ':checked' ) ) {
            localStorage.setItem( 'only_actived', 'true' );
            drivers_fields_vals[ "only_actived" ] = true;
        } else {
            localStorage.removeItem( 'only_actived' );
            drivers_fields_vals[ "only_actived" ] = false;
        }
        $("#start-load").val( '0' );
        global_values["start_load"] = $("#start-load").val();
        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
        .then( data => {
            drivers_elements.drivers_table_tbody.empty();
            drivers_load_drivers_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });

    });

    $("#only_broom").on( 'click', function() {
        if ( drivers_fields.only_broom.is( ':checked' ) ) {
            localStorage.setItem( 'only_broom', 'true' );
            drivers_fields_vals[ "only_broom" ] = true;
        } else {
            localStorage.removeItem( 'only_broom' );
            drivers_fields_vals[ "only_broom" ] = false;
        }
        $("#start-load").val( '0' );
        global_values["start_load"] = $("#start-load").val();
        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
        .then( data => {
            drivers_elements.drivers_table_tbody.empty();
            drivers_load_drivers_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });

    });

    // Init pages loading all users
    if ( window.location.pathname.includes('/drivers/')  ) {
        if ( localStorage.getItem( 'only_actived' ) ) {
            drivers_fields_vals[ "only_actived" ] = true;
        } else {
            drivers_fields_vals[ "only_actived" ] = false;
        }

        if ( localStorage.getItem( 'only_broom' ) ) {
            drivers_fields_vals[ "only_broom" ] = true;
        } else {
            drivers_fields_vals[ "only_broom" ] = false;
        }

        $("#start-load").val( '0' );
        global_values["start_load"] = $("#start-load").val();
        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
        .then( data => {
            drivers_load_drivers_list_ui( data );
        })
        .catch( err => {
            console.log( err );
        });
    }

    // $("#header-search").on( 'keyup', (event) => {
    //     const search_length = $("#header-search").val().length;
    //     if ( search_length > 3 ) {
    //         $("#start-load").val( '0' );
    //         drivers_fields_vals["start_load"] = $("#start-load").val();
    //         drivers_fields_vals.header_search = $("#header-search").val();
    //         fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
    //         .then( data => {
    //             drivers_elements.drivers_table_tbody.empty();
    //             drivers_load_drivers_list_ui( data );
    //         })
    //         .catch( err => {
    //             console.log( err );
    //         });
    //     }

    // });

    // $("#exit-search").on( 'click', function() {
    //     $("#header-search").val( '' );
    //     delete drivers_fields_vals[ "header_search" ];
    //     $("#start-load").val( '0' );
    //     drivers_fields_vals["start_load"] = $("#start-load").val();

    //     fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
    //     .then( data => {
    //         drivers_elements.drivers_table_tbody.empty();
    //         drivers_load_drivers_list_ui( data );
    //     })
    //     .catch( err => {
    //         console.log( err );
    //     });
    // })



    // // reload on scroll
    // $(window).on( 'scroll', function() {
    //     console.log( 'Se ejecuta window on scroll' );
    //     if ( drivers_elements.list_driver_layer.is(":visible") && !drivers_fields.is_finished.val() ) {
    //         if($(window).scrollTop() == $(document).height() - $(window).height()) {
    //             drivers_fields_vals["start_load"] = $("#start-load").val();
    //             fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'load_drivers_list' )
    //             .then( data => {
    //                 drivers_load_drivers_list_ui( data );
    //             })
    //             .catch( err => {
    //                 console.log( err );
    //             });
    //         }
    //     }
    // });

    $("#drivers-list-drivers").on( 'click', () => {
        $("#drivers-broom-tracks").removeClass('active');
        $("#drivers-list-drivers").addClass('active');
        hide_show('#brooms-tracker-layer','#list-driver-layer', 200);
    });

    $("#drivers-broom-tracks").on( 'click', () => {
        $("#drivers-list-drivers").removeClass('active');
        $("#drivers-broom-tracks").addClass('active');
        hide_show('#list-driver-layer','#brooms-tracker-layer', 200);
    });

    $("#btn-close-offcanvas").on( 'click', function() {
        $("#offcanvasBottom").removeClass( 'show' );
        $("#offcanvasBottom").css( 'visibility', 'hidden' );
        $("#offcanvas-backdrop").removeClass( 'show' );
        $("#offcanvas-backdrop").hide();
    });

    $("#offcanvas-backdrop").on( 'click', function() {
        $("#offcanvasBottom").removeClass( 'show' );
        $("#offcanvasBottom").css( 'visibility', 'hidden' );
        $("#offcanvas-backdrop").removeClass( 'show' );
        $("#offcanvas-backdrop").hide();
    });

    $(document).on( 'keyup', '.telefono-field', function( e ) {
        const phone = $(this).val();
        const id = $(this).data( 'id' );
        if ( (phone.startsWith('6') && phone.length === 9) || phone.length === 0 ) {

            drivers_fields_vals[ "driver_id" ] = id;
            drivers_fields_vals[ "phone" ] = phone;
            fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'update_phone' )
            .then( data => {
                console.log( data );
                spin_out();
                if ( data.status === 200 ) {

                    let update_phone_btn = '';
                    if ( data.driver.telefono.length === 0 ) {
                        update_phone_btn = '<button class="btn btn-danger btn-sm" disabled><i class="fas fa-phone-slash"></i></button>'
                        $("#phone-icon-"+id).html( update_phone_btn );
                        toast_msg( 'Teléfono de '+data.driver.conductor+' ELIMINADO correctamente', 'OK', 'success', 'down' );

                    } else {
                        update_phone_btn = '<a href="tel:' + data.driver.telefono + '" class="btn btn-success btn-sm"><i class="fas fa-phone-square-alt"></i>';
                        $("#phone-icon-"+id).html( update_phone_btn );
                        toast_msg( 'Teléfono de '+data.driver.conductor+' actualizado correctamente', 'OK', 'success', 'down' );
                    }
                } else {
                    toast_msg( 'Error al actualizar el teléfono de '+data.driver.conductor, 'Error', 'error', 'down' );
                }
            })
            .catch( err => {
                console.log( err );
            });
        } else if ( phone.length === 9 && !phone.startsWith('6') ) {
            toast_msg( 'El número de telefono debe empezar por 6 y tener 9 cifras', 'ALERTA', 'warning', 'down' );
        }

    });

    $(document).on( 'blur', '.api-user-field', function( e ) {
        const id = $(this).data( 'id' );
        const api_user = $(this).val();

        drivers_fields_vals[ "driver_id" ] = id;
        drivers_fields_vals[ "api_user" ] = api_user;

        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'update_api_user' )
        .then( data => {
            console.log( data );
            spin_out();
            if ( data.status === 200 ) {
                toast_msg( 'Usuario de '+data.driver.conductor+' actualizado correctamente', 'OK', 'success', 'down' );
            } else {
                toast_msg( 'Error al actualizar el usuario de '+data.driver.conductor, 'Error', 'error', 'down' );

            }
        })
        .catch( err => {
            console.log( err );
        });
    });

    $(document).on( 'blur', '.api-pass-field', function( e ) {
        const id = $(this).data( 'id' );
        const api_pass = $(this).val();

        drivers_fields_vals[ "driver_id" ] = id;
        drivers_fields_vals[ "api_pass" ] = api_pass;

        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'update_api_pass' )
        .then( data => {
            console.log( data );
            spin_out();
            if ( data.status === 200 ) {
                toast_msg( 'Contraseña de '+data.driver.conductor+' actualizada correctamente', 'OK', 'success', 'down' );
            } else {
                toast_msg( 'Error al actualizar la contraseña de '+data.driver.conductor, 'Error', 'error', 'down' );

            }
        })
        .catch( err => {
            console.log( err );
        });
    });

    $(document).on( 'change', '#select-broom-to-track', function( e ) {
        print_chart();
    });

    print_chart();

});
// $(funtion() {} ) // END


window.drivers_load_drivers_list_ui = ( data ) => {
    console.log(data);
    spin_out();

    $("#start-load").val( data.start_load );

    let drivers = data.drivers;

    let html = '';
    for ( let i=0; i<drivers.length; i++ ) {
        html += compose_html_tr_table_driver( drivers[i] );
    }

    drivers_elements.drivers_table_tbody.append(html);

    if ( !data.is_finished && $("#list-driver-layer").is(":visible") ) {
        toast_msg( 'CARGANDO...', 'Cargados ' +  data.start_load + ' de ' + data.drivers_total + ' conductores', 'success', 'down' );
        drivers_fields.is_finished.val( '' );
    } else {
        toast_msg( 'TODOS CARGADOS', 'Cargados ' +  data.drivers_total + ' de ' + data.drivers_total + ' conductores', 'info', 'down' );
        drivers_fields.is_finished.val( '1' );
    }

}


window.compose_html_tr_table_driver = ( driver ) => {

    let html = '';
    html += '<tr id="driver-'+driver.id+'">';
    if ( driver.has_phone ) {
        html +=     '<td id="phone-icon-'+driver.id+'"><a href="tel:' + driver.phone + '" class="btn btn-success btn-sm"><i class="fas fa-phone-square-alt"></i></td>';
    } else {
        html +=     '<td id="phone-icon-'+driver.id+'"><button class="btn btn-danger btn-sm" disabled><i class="fas fa-phone-slash"></i></button></td>';
    }

    if ( driver.has_position ) {
        html +=     '<td><a href="javascript:load_position_in_offcanvas(\''+driver.id+'\', \''+driver.name+'\', \''+driver.lat+'\', \''+driver.lng+'\')" class="btn btn-success btn-sm"><i class="fas fa-map-marker"></i></td>';
    } else {
        html +=     '<td><a href="#" class="btn btn-danger btn-sm" disabled><i class="fas fa-map-marker-alt"></i></td>';
    }

    html +=     '<td><a href="mailto:' + driver.email + '">' + driver.name + '</a></td>';
    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="text" class="form-control api-user-field" name="api_user" id="api-user-'+driver.id+'" data-id="'+driver.id+'" placeholder="Usuario" maxlength="9" value="'+driver.api_user+'">';
    html +=         '</div>';
    html +=     '</td>';
    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="text" class="form-control api-pass-field" name="api_pass" id="api-pass-'+driver.id+'" data-id="'+driver.id+'" placeholder="Contraseña" maxlength="9" value="'+driver.api_pass+'">';
    html +=         '</div>';
    html +=     '</td>';
    html +=     '<td>';
    html +=         '<div class="form-group">';
    html +=             '<input type="number" class="form-control telefono-field" name="telefono" id="telefono-'+driver.id+'" data-id="'+driver.id+'" placeholder="Teléfono" maxlength="9" value="'+driver.phone+'">';
    html +=         '</div>';
    html +=     '</td>';
    html +=     '<td>' + drivers_print_bootstrap_switch_from_true_false( driver.is_actived, driver.id ) + '</td>';
    html += '</tr>';

    return html;
}


window.load_position_in_offcanvas = ( id, name, lat, lng ) => {
    console.log( id, name, lat, lng );

    $("#offcanvasBottomLabel").text( 'Posicion de '+name );

    const loader = new Loader({
        apiKey: googleMapKey,
        //version: "weekly",
    });
    loader.load().then(() => {
        const myLatLng = new google.maps.LatLng( lat, lng );
        const map_driver = new google.maps.Map( document.getElementById( 'driver-only-map' ), {
            center: myLatLng,
            zoom: 16,
        });
        window.map_driver = map_driver;
    
        const marker_driver = new google.maps.Marker({
            position: myLatLng,
            map: map_driver,
            icon: car_icon_transitalia
        });
        // marker_driver.id = id;
        // marker_driver.name = name;
        // // Add marker to the array.
        // markers.push( marker_driver );
    });

    $("#offcanvasBottom").addClass( 'show' );
    $("#offcanvasBottom").css( 'visibility', 'visible' );
    $("#offcanvas-backdrop").show();
    $("#offcanvas-backdrop").addClass( 'show' );

}

window.drivers_boolean_change_action = ( driver_id ) => {
    
    drivers_fields_vals[ "driver_id" ] = driver_id;

    fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'modify_booleans' )
    .then( data => {
        console.log(data);
        spin_out();
        if ( data.status == 401 ) {
            toast_msg(data.error_msg, 'Error', 'error', 'down');
        } else if ( data.status == 200 ) {
            toast_msg(data.ok_msg, 'OK', 'success', 'down');
            if ( data.is_actived === false && localStorage.getItem( 'only_actived' ) ) {
                $("#driver-"+driver_id).remove();
            }
        }
    })
    .catch( err => {
        console.log( err );
    });
}


/***** HELPERS */
window.drivers_print_bootstrap_switch_from_true_false = ( val, id ) => {
    if ( val ) {
        return '<div class="form-check form-switch form-switch-lg text-center"><input class="form-check-input" type="checkbox" checked onchange="drivers_boolean_change_action(\''+id+'\');"></div>';
    } else {
        return '<div class="form-check form-switch form-switch-lg text-center"><input class="form-check-input" type="checkbox" onchange="drivers_boolean_change_action(\''+id+'\');"></div>';
    }
}





window.print_chart = () => {
    $("#month-chart").css( 'height', '0' );
    drivers_fields_vals[ "broom_id" ] = $( "#select-broom-to-track" ).val();
    fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'index_broom_kms' )
    .then( data => {
        spin_out();
        console.log( data );

        $("#escoba-name").text( `${ data.broom.conductor} - MES: ${ data.current_date }` );

        google.charts.load('current', { packages: [ 'corechart', 'line' ]});
        if ( data.current_month_kms.length === 0 ) {
            $("#month-chart").html( '<h5>Este escoba no tiene kilómetros este mes</h5>' );            
            google.charts.setOnLoadCallback( () => {
                drawChartYear( data.last_year_kms );
            });
        } else {
            google.charts.setOnLoadCallback( () => {
                drawChartMonth( data.current_month_kms );
                drawChartYear( data.last_year_kms );
            });
        }

        function drawChartMonth( month_kms ) {
            $("#month-chart").css( 'height', '400px' );
            var data = google.visualization.arrayToDataTable( month_kms );

            var options = {
                title: 'Kilometros por día del mes',
                curveType: 'line',
                legend: { position: 'bottom' }
            };

            var chart = new google.visualization.LineChart( document.getElementById( 'month-chart' ) );

            chart.draw(data, options);
        }

        function drawChartYear( year_kms ) {
            var data = google.visualization.arrayToDataTable( year_kms );

            var options = {
                title: 'Kilometros mes',
                curveType: 'line',
                legend: { position: 'bottom' }
            };

            var chart = new google.visualization.LineChart( document.getElementById( 'year-chart' ) );

            chart.draw(data, options);
        }

    })
    .catch( err => {
        console.log( err );
    });

    
}