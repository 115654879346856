//maps.js

console.log( 'Carga MAPS' );

// Referencias de jQuery
//var googleMapKey = 'AIzaSyCQ7vBkB7o8v8cTck2-2mcO1rD9XIkSdXk';
var googleMapKey = 'AIzaSyB-PMjG5m33yaS9XbrJTQ9OYcJhNdPK-CA';
// var map;
// var marker;
// var move_marker;
// var markers = [];

import { Loader } from "@googlemaps/js-api-loader";

$(function() {

    const loader = new Loader({
        apiKey: googleMapKey,
        //version: "weekly",
    });

    let myLatLng = { lat: 40.41676170241468, lng: -3.7037922032290806 };

    loader.load().then(() => {
        const map = new google.maps.Map( document.getElementById( 'map-wrapper' ), {
            center: myLatLng,
            zoom: 11,
            styles: standar_map,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        });
        window.map = map;

        directionsDisplay = new google.maps.DirectionsRenderer();
        window.directionsDisplay = directionsDisplay;

    })
    .then( () => {
        if ( localStorage.getItem( 'map-style' ) ){
            $("#select-map-type").val( localStorage.getItem( 'map-style' ) );
            compose_map_options( localStorage.getItem( 'map-style' ) );
        }
        set_markers_onload();
    })
    .catch( err => {
        console.log( err );
    });

    // Lauch routes buttons
    if ( localStorage.getItem( 'auth_token' ) ) {
        list_brooms_with_services_sidebar();
    }


    $(document).on( 'change', '#select-city', function() {

        if ( $(this).val() === 'MADRID' ) {
            let myLatLng = { lat: 40.41676170241468, lng: -3.7037922032290806 }; // Madrid
            map.setCenter( myLatLng );
        } else if ( $(this).val() === 'BARCELONA' ) {
            let myLatLng = { lat: 41.395994899900174, lng: 2.14774021387476836 }; // Barcelona
            map.setCenter( myLatLng );
        } else {
            let myLatLng = { lat: 38.984819071061516, lng: -3.927351283211884 }; // Ciudad Real
            map.setCenter( myLatLng );
        }

        reload_drivers_select_with_city();

        list_brooms_with_services_sidebar();

        set_markers_onload();
    });


    $(document).on( 'click', '.print-broom-service-route', function() {
        // const conductor = $(this).data( 'conductor' );
        // const date_time = $(this).data( 'date_time' );
        // const delivery = $(this).data( 'delivery' );
        const delivery_lat = $(this).data( 'delivery_lat' );
        const delivery_lng = $(this).data( 'delivery_lng' );
        const origin_lat = $(this).data( 'origin_lat' );
        const origin_lng = $(this).data( 'origin_lng' );
        // const pickup = $(this).data( 'pickup' );
        const pickup_lat = $(this).data( 'pickup_lat' );
        const pickup_lng = $(this).data( 'pickup_lng' );
        const service_id = $(this).data( 'service_id' );
        const service_status = $(this).data( 'service_status' );

        var is_present = false;

        for ( let i=0; i<map_routes.length; i++ ) {
            if ( map_routes[i].id === parseInt( service_id ) ) {
                is_present = true;
                map_routes[i].setMap( null );
                map_routes.splice( [i], 1 );
                break;
            }
        }
        if ( !is_present ) {
            // Last para false because this event manage brooms
            // Includes 0.0 lat lng for ITV exception in brooms
            print_route_on_map( delivery_lat, delivery_lng, 0.0, 0.0, origin_lat, origin_lng, pickup_lat, pickup_lng, service_id, service_status, false );
        }

    });

    $("#btn-close-offcanvas").on( 'click', function() {
        $("#offcanvasRight").removeClass( 'show' );
        $("#offcanvasRight").css( 'visibility', 'hidden' );
        $("#offcanvas-backdrop").removeClass( 'show' );
        $("#offcanvas-backdrop").hide();

        directionsDisplay.setMap( null );
    });

    $("#offcanvas-backdrop").on( 'click', function() {
        $("#offcanvasRight").removeClass( 'show' );
        $("#offcanvasRight").css( 'visibility', 'hidden' );
        $("#offcanvas-backdrop").removeClass( 'show' );
        $("#offcanvas-backdrop").hide();

        directionsDisplay.setMap( null );
    });

    $(document).on( 'dblclick', '.print-broom-service-route', function() {
        $( "#service-date-time" ).val( $(this).data( 'date_time' ) );
        $( "#service-observations" ).val( $(this).data( 'service_observations' ) );
        $( "#edit-service-id" ).val( $(this).data( 'service_id' ) );
        
        $("#offcanvasRight").addClass( 'show' );
        $("#offcanvasRight").css( 'visibility', 'visible' );
        $("#offcanvas-backdrop").show();
        $("#offcanvas-backdrop").addClass( 'show' );

    });

    $( document ).on( 'change', '#select-driver-in-map', function() {
        const driver_id = parseInt( $( this ).val() );

        let is_found = false;

        for ( let i=0; i<markers.length; i++ ) {
            
            if ( markers[i].id === driver_id ) {

                is_found = true;

                my_marker = markers[i];

                my_marker.setAnimation( google.maps.Animation.BOUNCE );

                setTimeout(() => {
                    my_marker.setAnimation( null );
                }, 3000);
            }
        }

        if ( !is_found ) {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                html: 'No se ha encontrado este conductor en el mapa'
            });
        }

    });

    $( document ).on( 'click', '#services-today', function() {
        $( "#services-today" ).removeClass( 'btn-default' ).addClass( 'btn-success' );
        $( "#services-tomorrow" ).removeClass( 'btn-success' ).addClass( 'btn-default' );

        // Reload sidebar
        list_brooms_with_services_sidebar();
    });

    $( document ).on( 'click', '#services-tomorrow', function() {
        $( "#services-today" ).removeClass( 'btn-success' ).addClass( 'btn-default' );
        $( "#services-tomorrow" ).removeClass( 'btn-default' ).addClass( 'btn-success' );

        // Reload sidebar
        list_brooms_with_services_sidebar();
    });

    $( document ).on( 'click', '#service-update-btn', function() {
        drivers_fields_vals[ "service_date_time" ] = $( "#service-date-time" ).val();
        drivers_fields_vals[ "service_observations" ] = $( "#service-observations" ).val();
        drivers_fields_vals[ "edit_service_id" ] = $( "#edit-service-id" ).val();

        fetch_actions_action( global_values, drivers_fields_vals, global_urls.drivers, 'update_service_time_and_observations' )
        .then( data => {
            console.log(data);
            spin_out();
            if ( data.status == 500 ) {
                Swal.fire({
                    icon: 'error',
                    title: "ERROR",
                    html: data.error
                });
            } else if ( data.status == 200 ) {

                $("#offcanvasRight").removeClass( 'show' );
                $("#offcanvasRight").css( 'visibility', 'hidden' );
                $("#offcanvas-backdrop").removeClass( 'show' );
                $("#offcanvas-backdrop").hide();

                // Reload sidebar
                list_brooms_with_services_sidebar();
            }
        })
        .catch( err => {
            console.log( err );
        });
    });

    $( document ).on( 'change', '.print-driver-service-route', function( event ) {
        const ori_lat = $(this).data( 'ori_lat' );
        const ori_lng = $(this).data( 'ori_lng' );
        const itv_lat = $(this).data( 'itv_lat' );
        const itv_lng = $(this).data( 'itv_lng' );
        const dest_lat = $(this).data( 'dest_lat' );
        const dest_lng = $(this).data( 'dest_lng' );
        const service_id = $(this).data( 'service_id' );

        console.log( ori_lat );
        console.log( ori_lng );
        console.log( itv_lat );
        console.log( itv_lng );
        console.log( dest_lat );
        console.log( dest_lng );
        console.log( service_id );

        if ( $(this).prop( 'checked' ) ) {
            console.log( 'pinta ruta' );
            // Last param is true because this method only used for drivers
            print_route_on_map( dest_lat, dest_lng, itv_lat, itv_lng, ori_lat, ori_lng, '', '', service_id, 2, true );
        } else {
            console.log( 'borra ruta' );
            for ( let i=0; i<map_routes.length; i++ ) {
                if ( map_routes[i].id === parseInt( service_id ) ) {
                    map_routes[i].setMap( null );
                    map_routes.splice( [i], 1 );
                    break;
                }
            }
        }

    });

    $( document ).on( 'click', '#clean-map-routes', function() {
        for ( let i=0; i<map_routes.length; i++ ) {
            map_routes[i].setMap( null );
        }
        map_routes = [];

        if ( $("#select-city").val() === 'MADRID' ) {
            let myLatLng = { lat: 40.41676170241468, lng: -3.7037922032290806 }; // Madrid
            map.setCenter( myLatLng );
        } else if ( $("#select-city").val() === 'BARCELONA' ) {
            let myLatLng = { lat: 41.395994899900174, lng: 2.14774021387476836 }; // Barcelona
            map.setCenter( myLatLng );
        }

        map.setZoom( 11 );

    });	

    $( document ).on( 'change', '#select-map-type', function( event ) {

        localStorage.setItem( 'map-style', $("#select-map-type").val() );
        compose_map_options( localStorage.getItem( 'map-style' ) );

        // if( localStorage.getItem( 'map-style' ) ) {
        //     compose_map_options( localStorage.getItem( 'map-style' ) );
        // } else {
        //     localStorage.setItem( 'map-style', $("#select-map-type").val() );
        //     compose_map_options( $("#select-map-type").val() );
        // }

    });


});

window.set_markers_onload = () => {

    /// Send fetch
    fetch_actions_action_with_file( global_values, maps_fields_vals, global_urls.maps, 'reload_drivers_for_map' )
    .then( data => {
        spin_out();

        console.log( 'set_markers_onload:', data );

        clear_overlays();

        const drivers = data.drivers;
        const brooms = data.brooms;

        print_drivers_on_map( drivers );
        print_drivers_on_map( brooms );
    })
    .catch( err => {
        console.log( err );
    });

}

window.clear_overlays = () => {
    for (var i = 0; i < markers.length; i++ ) {
        markers[i].setMap(null);
    }
    markers = [];
}

window.print_drivers_on_map = ( drivers ) => {

    let icon = car_icon_transitalia;
    
    for ( let i=0; i<drivers.length; i++ ) {

        const driver = drivers[i];

        if ( driver.escoba ) {
            icon = icon_colors[ convert_broom_id_in_value_between_0_and_5( driver.id ) ];
        } else {
            icon[ 'fillColor' ] = driver.icon_color;
        }

        //console.log( drivers[i] );

        const marker = new google.maps.Marker({
            position: { lat: parseFloat( driver.lat ), lng: parseFloat( driver.lng ) },
            map: map,
            icon: icon,
            title: driver.conductor,
            id: driver.id
        });

        const infowindow = new google.maps.InfoWindow({
            content: compose_maps_info_window( driver ),
        });

        marker.addListener("click", () => {
            infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });
        });

        markers.push( marker );

    }

}

window.print_route_on_map = ( delivery_lat, delivery_lng, itv_lat, itv_lng, origin_lat, origin_lng, pickup_lat, pickup_lng, service_id, service_status, is_driver ) => {

    var geocoder;
    var directionsDisplay;
    var directionsService = new google.maps.DirectionsService();
    var locations = compose_driver_service_route_points( delivery_lat, delivery_lng, itv_lat, itv_lng, origin_lat, origin_lng, pickup_lat, pickup_lng, service_status, is_driver );

    console.log( locations );

    // if ( itv_lat == 0.0 ) {
    //     locations = [
    //         ['Inicio', origin_lat, origin_lng, 1],
    //         ['Recogida', pickup_lat, pickup_lng, 2],
    //         ['Entrega', delivery_lat, delivery_lng, 3]
    //     ];
    // } else {
    //     locations = [
    //         ['Inicio', origin_lat, origin_lng, 1],
    //         ['Recogida', pickup_lat, pickup_lng, 2],
    //         ['ITV', itv_lat, itv_lng, 3],
    //         ['Entrega', delivery_lat, delivery_lng, 4]
    //     ];
    // }

    // if ( service_status == 2 ) {
    //     if ( itv_lat == 0.0 ) {
    //         locations = [
    //             //['Inicio', origin_lat, origin_lng, 1],
    //             ['Recogida', pickup_lat, pickup_lng, 1],
    //             ['Entrega', delivery_lat, delivery_lng, 2]
    //         ];
    //     } else {
    //         locations = [
    //             ['Inicio', origin_lat, origin_lng, 1],
    //             //['Recogida', pickup_lat, pickup_lng, 2],
    //             ['Entrega', delivery_lat, delivery_lng, 2]
    //         ];
    //     }
    // }

    // With last param create a different polyline color for drivers and brooms
    let route_color = { strokeColor: 'green', strokeWeight: 5, strokeOpacity: 0.8 };
    if ( is_driver ) {
        route_color = { strokeColor: 'red', strokeWeight: 5, strokeOpacity: 0.8 };
    }

    directionsDisplay = new google.maps.DirectionsRenderer({
        id: service_id,
        polylineOptions: route_color
    });
    window.directionsDisplay = directionsDisplay;
    
    directionsDisplay.setMap(map);
    map_routes.push( directionsDisplay );
    
    var infowindow = new google.maps.InfoWindow();

    var marker, i;
    var request = {
        travelMode: google.maps.TravelMode.DRIVING
    };

    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        });

        google.maps.event.addListener(marker, 'click', ( function(marker, i ) {
            return function() {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));

        if (i == 0) {
            request.origin = marker.getPosition();
        } else if (i == locations.length - 1) {
            request.destination = marker.getPosition();
        } else {
            if ( !request.waypoints ) {
                request.waypoints = [];
            }
            request.waypoints.push({
                location: marker.getPosition(),
                stopover: true
            });
        }

    }

    directionsService.route(request, function( result, status ) {
        if ( status == google.maps.DirectionsStatus.OK ) {
            directionsDisplay.setDirections( result );
            if ( is_driver ) {
                toast_msg(`Distancia: ${ directionsDisplay.directions.routes[0].legs[0].distance.text }<br>Tiempo estimado: ${ directionsDisplay.directions.routes[0].legs[0].duration.text }`, 'Información Ruta:', 'success', 'down');
            }
        }
    });
}

window.list_brooms_with_services_sidebar = () => {

    if ( $( "#services-today" ).hasClass( 'btn-success' ) ){
        maps_fields_vals[ "today" ] = 1;
    } else {
        maps_fields_vals[ "today" ] = 0;
    }

    maps_fields_vals[ "city_select" ] = $( "#select-city option:selected" ).val();

    /// Send fetch
    fetch_actions_action_with_file( global_values, maps_fields_vals, global_urls.maps, 'list_brooms_with_services' )
    .then( data => {
        spin_out();

        if ( data.status === 200 ) {
            const brooms = data.brooms;

            let html = '';
            for ( let i=0; i<brooms.length; i++ ) {
                html += compose_broom_sidebar_element( brooms[ i ] );
            }

            $("#list-brooms-sidebar-wrapper").html( html );

        } else {
            // There are no services now
            $("#list-brooms-sidebar-wrapper").empty();

            // Swal.fire({
            //     icon: 'error',
            //     title: "Sin servicios",
            //     html: 'No se han encontrado servcios asignados a escobas para mañana'
            // });
        }

        
    })
    .catch( err => {
        console.log( err );
    });

}

window.compose_broom_sidebar_element = ( broom ) => {

    let html = '';
    const services = broom.services;

    html += `<span class="nav-link list-brooms-sidebar">`;
    html +=     `<p style="color: ${ broom_color[ convert_broom_id_in_value_between_0_and_5( broom.broom.broom_id ) ] }"><i class="fas fa-car-alt"></i> ${ broom.broom.broom_name }</p>`;
    //html +=     `<ul class="list-brooms-ul">`;


    html +=     `<div class="row">`;

    for ( let i=0; i<services.length; i++ ) {
        html += `<div class="col-4" style="margin-bottom: 0.5rem;"><button class="btn ${ get_service_btn_color_from_status( services[i].service_status ) } btn-sm btn-block print-broom-service-route"`;
        html += ` data-conductor="${ services[i].conductor }"`;
        html += ` data-date_time="${ services[i].date_time }"`;
        html += ` data-delivery="${ services[i].delivery }"`;
        html += ` data-delivery_lat="${ services[i].delivery_lat }"`;
        html += ` data-delivery_lng="${ services[i].delivery_lng }"`;
        html += ` data-origin_lat="${ services[i].origin_lat }"`;
        html += ` data-origin_lng="${ services[i].origin_lng }"`;
        html += ` data-pickup="${ services[i].pickup }"`;
        html += ` data-pickup_lat="${ services[i].pickup_lat }"`;
        html += ` data-pickup_lng="${ services[i].pickup_lng }"`;
        html += ` data-service_id="${ services[i].service_id }"`;
        html += ` data-service_status="${ services[i].service_status }"`;
        html += ` data-service_observations="${ services[i].service_observations }"`;
        html += `>${ services[i].date_time }</button></div>`;
        //html += `>${ services[i].date_time }</button></li>`;
    }

    html +=    `</div>`;
    //html +=    `</ul>`;
    html += `</span>`;

    return html;

}

window.get_service_btn_color_from_status = ( status ) => {
    if ( status === 0 ) {
        return 'btn-default';
    } else if ( status === 1 ) {
        return 'btn-primary';
    } else if ( status === 2 ) {
        return 'btn-warning';
    } else if ( status === 3 ) {
        return 'btn-success';
    }
}

window.reload_drivers_select_with_city = () => {

    maps_fields_vals[ "city_select" ] = $( "#select-city option:selected" ).val();

    /// Send fetch
    fetch_actions_action_with_file( global_values, maps_fields_vals, global_urls.maps, 'reload_drivers_select_with_city' )
    .then( data => {
        spin_out();
        console.log( data );

        $( "#select-driver-in-map" ).empty();

        $( "#select-driver-in-map" ).append( '<option value="" selected="">Seleccionar condutor...</option>' );

        const drivers = data.drivers;
        for ( let i=0; i<drivers.length; i++ ) {
            $( "#select-driver-in-map" ).append( `<option value="${ drivers[i].conductor.split(' - ')[0] }">${ drivers[i].conductor }</option>` );
        }        
    })
    .catch( err => {
        console.log( err );
    });

}

window.convert_broom_id_in_value_between_0_and_5 = ( id ) => {
    let myFunc = num => Number(num);
    intArr = Array.from( String( id ) , myFunc );

    var sum = intArr.reduce(function(a, b){
        return a + b;
    }, 0);

    while ( sum > 5 ) {
        sum = parseInt( sum/4 );
    }

    return sum;
}

window.compose_maps_info_window = ( driver ) => {

    let hora_posicion = driver.hora_posicion;
    if ( driver.escoba ) {
        hora_posicion = driver.hora_posicion.substring(11,16);
    }

    let info = '';
    info += `<div style="color: #2c2c2c;">`;
    info += `<h6>${ driver.conductor } - ${ hora_posicion }</h6>`; //drivers[i].hora_posicion.substring(11,16)
    info += `<p>Llamar por teléfono: <a href="tel:${ driver.telefono }">${ driver.telefono }</a></p>`;
        
    if ( !driver.escoba ) {
        const services = driver.services;
        if( services.length !== 0 ) {
            info += `<h6>Servicios:</h6>`;
            for ( let j=0; j<services.length; j++ ) {
                const service = services[ j ];
                info += `<div style="min-width: 300px;">`;
                info +=     `<div class="form-check form-switch">`;
                info +=     `  <input class="form-check-input print-driver-service-route" data-ori_lat="${ service.origen_lat }" data-ori_lng="${ service.origen_lng }" data-itv_lat="${ service.itv_lat }" data-itv_lng="${ service.itv_lng }" data-dest_lat="${ service.destino_lat }" data-dest_lng="${ service.destino_lng }" data-service_id="${ service.id }" type="checkbox" value="" id="route-${ service.id }" />`;
                info +=     `  <label class="form-check-label" style="margin-top: 0.2rem;" for="route-${ service.id }">Hora: ${ service.hora4 } - (${ service.estado_id }) Matricula: ${ service.matricula } - ${ service.icon }</label>`;
                info +=     `</div>`;
                info += `</div>`;
            }
        } else {
            info += `<h6>Sin servicios asignados</h6>`;
        }
    }

    info += `</div>`;

    return info;

}

window.compose_map_options = ( map_style ) => {

    if ( map_style === 'standar_map' ) {
        map.setOptions( { styles: standar_map } );
    } else if ( map_style === 'night_map' ) {
        map.setOptions( { styles: night_map } );
    }  else if ( map_style === 'dark_map' ) {
        map.setOptions( { styles: dark_map } );
    }

}

window.compose_driver_service_route_points = ( delivery_lat, delivery_lng, itv_lat, itv_lng, origin_lat, origin_lng, pickup_lat, pickup_lng, service_status, is_driver ) => {

    var locations;

    if ( is_driver ) {

        if ( itv_lat == 0.0 ) {
            if ( service_status == 2 ) {
                locations = [
                    ['Recogida', origin_lat, origin_lng, 1],
                    ['Entrega', delivery_lat, delivery_lng, 2]
                ];
            } else if ( service_status == 11 ) {
                locations = [
                    ['Recogida', origin_lat, origin_lng, 1],
                    ['Entrega', delivery_lat, delivery_lng, 2]
                ];
            } else {
                locations = [
                    ['Inicio', origin_lat, origin_lng, 1],
                    ['Entrega', delivery_lat, delivery_lng, 2]
                ];
            }

        } else {
            if ( service_status == 2 ) {
                locations = [
                    ['Recogida', origin_lat, origin_lng, 1],
                    ['ITV', itv_lat, itv_lng, 2],
                    ['Entrega', delivery_lat, delivery_lng, 3]
                ];
            } else if ( service_status == 11 ) {
                locations = [
                    ['Recogida', origin_lat, origin_lng, 1],
                    ['ITV', itv_lat, itv_lng, 2],
                    ['Entrega', delivery_lat, delivery_lng, 3]
                ];
            } else if ( [ 12, 13, 14 ].includes(service_status) ) {
                locations = [
                    ['Recogida', origin_lat, origin_lng, 1],
                    ['ITV', itv_lat, itv_lng, 2],
                    ['Entrega', delivery_lat, delivery_lng, 3]
                ];
            } else {
                locations = [
                    ['Inicio', origin_lat, origin_lng, 1],
                    ['ITV', itv_lat, itv_lng, 2],
                    ['Entrega', delivery_lat, delivery_lng, 3]
                ];
            }
        }

    } else {
        if ( service_status == 1 ) {
            locations = [
                ['Inicio', origin_lat, origin_lng, 1],
                ['Recogida', pickup_lat, pickup_lng, 2],
                ['Entrega', delivery_lat, delivery_lng, 3]
            ];
        } else {
 
            locations = [
                //['Inicio', origin_lat, origin_lng, 1],
                ['Recogida', pickup_lat, pickup_lng, 1],
                ['Entrega', delivery_lat, delivery_lng, 2]
            ];
        }
    }

    return locations;

}
